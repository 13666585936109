.footer {
  &__menus {
    background-color: $light-gray;
    padding: $global-padding * 2 0;
    margin-top: $global-margin * 8;
    @include media-breakpoint-down(xs) {
      text-align: center;
      margin-top: $global-margin * 2;
    }
    .menu__item {
      & > a {
        font-weight: 600;
      }
    }
    &__social {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include media-breakpoint-down(sm) {
        margin-top: $global-margin * 3;
      }
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }
  &__copy {
    padding: $global-padding * 2 0 $global-padding * 3;
    &-text {
      text-align: right;
    }
  }
  &__logo {
    margin-bottom: $global-margin;
    svg {
      height: 38px;
      width: 113px;
    }
  }
  hr {
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    a {
      color: $black;
      line-height: 2;
    }
  }
  a {
    transition: 0.3s;
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
    &.social-footer {
      background-color: $dark-blue;
      border-radius: 100%;
      display: inline-block;
      height: 36px;
      margin-bottom: $global-margin;
      margin-right: $global-margin;
      width: 36px;
      svg {
        height: 16px;
        margin-top: 8px;
        width: 100%;
      }
    }
  }
}

.maincontent {
  @include media-breakpoint-down(sm) {
    margin-top: $global-margin * 7;
  }
}
