#product-list-image {
  background-position: top;
  background-repeat: repeat-x;
  background-size: cover;
  @include media-breakpoint-down(sm) {
    padding: 17 * $global-padding 0 0;
    margin-top: -1 * $global-margin;
  }
  @include media-breakpoint-up(md) {
    padding: 20 * $global-padding 0 0;
  }
}

.product-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-list {
  text-align: center;
  margin-bottom: $global-margin * 2;
  img {
    max-width: 100%;
  }
  &-item-name {
    margin: $global-margin / 2 0;
    display: block;
  }
  .panel-footer {
    color: $skull-gray;
    text-transform: uppercase;
  }
  .text-center {
    position: relative;
  }
  &__sale {
    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
    &__text {
      position: absolute;
      top: 0.5rem;
      left: 0.15rem;
      color: $white;
      font-size: 0.8rem;
      text-transform: uppercase;
      transform: rotate(-45deg);
      font-weight: 500;
    }
  }
  &__header {
    @include media-breakpoint-down(sm) {
      padding: $global-padding * 2 0;
    }
    @media (max-width: 370px) {
      padding-top: 5rem;
    }
    h1 {
      font-size: 2.5rem;
      color: $body-color;
      padding-bottom: 0;
      text-align: left;
    }
  }
}
