.label {
  &:before {
    content: '';
    display: inline-block;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 1rem;  
    margin-bottom: 1px;
    margin-right: $global-margin / 2;
  }
  &-success {
    &:before {
      background-color: $green;
    }
  }
  &-danger {
    &:before {
      background-color: $red;
    }
  }
  &-default {
    &:before {
      background-color: $gray;
    }
  }
}
