.form-control,
select,
input {
  color: $body-color;
  border-radius: 8px;
  border: 1px solid $gray;
  &:focus {
    color: $body-color;
    border-color: $gray;
    background-color: $beige;
    outline: none;
  }
  &::placeholder {
    color: $gray;
    font-family: $font-family-sans-serif;
    font-weight: 300;
  }
  &[type="checkbox"] {
    -webkit-appearance: none;
    border-color: $turquoise;
    border-radius: 2px;
    cursor: pointer;
    height: 8px;
    transition-duration: 300ms;
    width: 8px;
    &:checked {
      background-color: $turquoise;
    }
  }
}

.form-group {
  text-align: left;
  position: relative;
  .phone-prefix-input {
    display: grid;
    grid-template-columns: 6rem calc(100% - 6rem);
    align-items: stretch;
    input {
      border-left-color: transparent;
      border: {
        bottom-left-radius: 0;
        top-left-radius: 0;
      }
    }
    select {
      border: {
        bottom-right-radius: 0;
        top-right-radius: 0;
      }
    }
  }
}

.input-btn {
  position: relative;
  .btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.deliveryform {
  select {
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: $global-margin / 2;
      margin-left: 0;
    }
    background-color: $white;
    height: calc(2.25rem + 2px);
    &:focus {
      outline: none;
    }
  }
}

.search-form {
  width: 100%;
  position: relative;
  transition: $transition-base;
  &.search-form--hidden {
    transform: translateX(-100vw);
  }
  &__close-icon {
    position: absolute;
    cursor: pointer;
    padding: $global-padding * 1.9 $global-padding;
    img {
      width: 22px;
    }
  }
  .form-control {
    height: 53px;
    width: 100%;
    border-radius: $button-border-radius;
    padding: $global-padding;
    font-size: 0.8rem;
  }
  .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    border-left: solid 1px $gray;
    outline: 0;
    display: flex;
    align-items: center;
    @include transition(background, 0.3s) ;
    img {
      height: 1.5rem;
      padding: $global-padding / 7;
    }
  }
  @include media-breakpoint-down(sm) {
    display: block;
    left: 0;
    position: absolute;
    max-width: 100%;
    float: right;
    top: 0;
    z-index: 5;
    margin-top: 0;
    .form-control {
      padding: $global-padding * 2 $global-padding * 2 $global-padding * 2
        $global-padding * 4;
      border: 0;
      height: 5.9rem;
    }
    .mobile-close-search {
      position: absolute;
      top: 1.7rem;
      left: 0.5rem;
    }
    .btn {
      border-radius: 0;
      height: 6rem;
      right: 0;
      top: 0;
    }
  }
}

input#id_quantity {
  padding: $global-padding / 2;
  &:focus {
    outline: none;
  }
}

.help-block {
  font-size: 0.8rem;
  margin-top: $global-margin / 2;
}

.has-error {
  .help-block {
    color: $red;
  }
}

.radio {
  margin-bottom: $global-margin / 2;
  ul {
    list-style: none;
    padding-left: 0;
  }
  input {
    -webkit-appearance: none;
    cursor: pointer;
    border: 1px solid $turquoise;
    display: inline-block;
    height: 14px;
    margin: $global-margin / 4 $global-margin / 2 0 0;
    position: relative;
    vertical-align: top;
    width: 14px;
    &:checked {
      &:after {
        background: $turquoise;
        border-radius: 100%;
        content: "";
        height: 8px;
        left: 2px;
        position: absolute;
        top: 2px;
        width: 8px;
      }
    }
  }
  span,
  .address-details {
    display: inline-block;
  }
  label {
    margin-right: $global-margin;
    span {
      display: inline;
    }
  }
}

.relative {
  position: relative;
}

.passIcon {
  max-width: 15px;
  max-height: 15px;
  position: absolute;
  top: 2.8rem;
  right: 0.5rem;
  cursor: pointer;
}

// ATM there is no better solution to set
// border-radius on select inputs - 12.2017
select.form-control {
  appearance: none;
  border-radius: 2px;
  background: url("../images/arrow-select.svg") 100% 50% no-repeat / 24px 14px;
}
