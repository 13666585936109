.order-details {
  &__header {
    margin: $global-margin 0 $global-margin * 2;
  }
  &__addresses {
    margin-top: $global-margin * 2;
  }
  &__product {
    .col-3 {
      margin-top: $global-margin / 2;
    }
    &__description {
      a {
        transition: 0.3s;
        &:hover {
          color: $skull-gray;
        }
      }
      img {
        vertical-align: top;
        margin-right: $global-margin;
      }
      span {
        line-height: 60px;
      }
    }
  }
  &__total {
    padding-top: $global-padding;
    color: $skull-gray;
  }
  &__last-row {
    border-bottom-color: $skull-gray;
  }
  &__table-footer {
    @include media-breakpoint-up(sm) {
      padding-left: calc(60px + #{$global-padding} * 2);
    }
  }
  &__table-header {
    margin-top: 2rem;
  }
}

.order-notes {
  margin-top: 2 * $global-margin;
  > p {
    white-space: pre-wrap;
  }
}
