a.btn {
  display: inline-block;
}
.btn {
  transition: 0.3s;
  border-radius: $button-border-radius;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1rem;
  cursor: pointer;
  font-family: $font-family-sans-serif;
  white-space: normal;
  line-height: 1.25;
  &.home__button {
    text-transform: uppercase;
    font-size: $h3-font-size;
    color: $white;
    background-color: transparent;
    border: 1px solid $white;
    padding: $global-padding $global-padding * 2;
    &:hover {
      background-color: $white;
      color: $body-color;
    }
  }
  &-primary {
    border: none;
    color: $white;
    padding: $global-padding $global-padding * 4;
    &:hover {
      background-color: #42beab;
      border: none;
      color: $white;
    }
  }
  &.secondary {
    border: 1px solid $light-turquoise;
    padding: $global-padding $global-padding * 2;
    background-color: $white;
    color: $light-turquoise;
    &:hover {
      border-color: #42beab;
      color: #42beab;
    }
  }
  &.danger {
    border: 1px solid $red;
    background-color: transparent;
    color: $red;
    padding: $global-padding $global-padding * 4;
    &:hover {
      background-color: $dark-red;
      color: $white;
    }
  }
  &.narrow {
    padding: $global-padding / 2 $global-padding * 2.5;
  }
  &.gray {
    background-color: $gray;
    border: 0;
    color: $white;
    padding: $global-padding $global-padding * 4;
    &:hover {
      background-color: $darken-gray;
    }
  }
  &.btn-link {
    padding: 0;
    vertical-align: baseline;
    color: $gray;
    text-transform: none;
  }
  &.social-btn {
    svg {
      vertical-align: text-bottom;
      border-right: 1px solid $white;
      margin-right: $global-margin / 4;
      padding-right: $global-margin / 4;
    }
    &.facebook {
      background: #3b5998;
      &:hover {
        background: darken(#3b5998, 10%);
      }
    }
    &.google {
      background: #dd4b39;
      &:hover {
        background: darken(#dd4b39, 10%);
      }
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
