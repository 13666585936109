.table {
  &__header {
    border-bottom: 1px solid $gray;
    padding-bottom: $global-padding / 2;
    small {
      color: $skull-gray;
      text-transform: uppercase;
    }
  }
  &__row {
    border-bottom: 1px solid $gray;
    padding: $global-padding 0;
    p {
      margin-bottom: 0;
    }

    &.fulfillment__row {
      padding: 0;
    }
  }
}
