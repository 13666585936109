.styleguide {
  &__nav {
    a {
      display: block;
    }
    margin-bottom: $global-margin * 2;
    &.fixed {
      position: fixed;
      top: 20px;
      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }
  }
  &__section {
    border: 2px solid $light-gray;
    padding: $global-padding;
    border-radius: 0px;
    margin-bottom: $global-margin;
    p {
      margin-bottom: 0;
    }
    [contenteditable='true'] {
      &:focus {
        outline: none;
        opacity: 0.9;
      }
    }
  }
  &__tittle {
    background-color: $light-gray;
    margin-bottom: 0;
    padding: $global-padding;
    p {
      margin-bottom: 0;
      padding-top: $global-padding;
    }
    h3 {
      margin-bottom: 0;
      padding-top: 0;
      
    }
  }
  &__sub-tittle {
    background-color: $light-gray;
    padding: $global-padding;
    margin-bottom: 0;
    margin-top: -$global-padding;
    p {
      margin-bottom: 0;
    }
  }
  &__content {
    &__block {
      padding-bottom: $global-padding * 3;
      border-top: 1px solid $gray;
      &__bold {
        font-weight: 700;
      }
      &__light {
        font-weight: 300;
      }
      &__color {
        text-align: center;
        div {
          width: 100px;
          height: 100px;
          border-radius: 150px;
          margin: 0 auto $global-margin;
        }
        &--body-color {
          background-color: $body-color;
        }
        &--white {
          background-color: $white;
          border: 1px solid $gray;
        }
        &--light-gray {
          background-color: $light-gray;
        }
        &--gray {
          background-color: $gray;
        }
        &--darken-gray {
          background-color: $darken-gray;
        }
        &--skull-gray {
          background-color: $skull-gray;
        }
        &--blue {
          background-color: $blue;
        }
        &--darken-blue {
          background-color: $darken-blue;
        }
        &--red {
          background-color: $red;
        }
        &--dark-red {
          background-color: $dark-red;
        }
        &--green {
          background-color: $green;
        }
        &--beige {
          background-color: $beige;
          border: 1px solid $gray;
        }
      }
      &__grid {
        text-align: center;
        &:not(:last-of-type) {
          margin-bottom: $global-margin;
        }
        p {
          background-color: $light-gray;
          padding: $global-padding 0;
        }
      }
      &#helpers {
        .styleguide__section {
          div {
            margin-bottom: $global-margin * 2;
          }
        }
      }
      &#icons {
        svg {
          width: 35px;
          height: 35px;
          display: inline-block;
          margin-right: $global-margin;
        }
      }
      &#buttons {
        button {
          margin: $global-margin / 2;
        }
        .home__block1 {
          margin-top: $global-margin;
        }
      }
      .deliveryform {
        label, select {
          float: left;
        }
      }
      .search-form {
        @include media-breakpoint-down(sm) {
          margin-bottom: $global-margin;
          position: relative;
          left: 0;
          .btn {
            top: 0;
            right: 0;
          }
        }
      }
      .product-list, .card-block, .alert:last-of-type, .form-group {
        margin-bottom: 0;
      }
    }
  }
}
