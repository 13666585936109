.no-results {
	max-width: 30rem;
	margin: auto;
	text-align: center;
	h2 {
		padding-top: $global-padding;
		font-size: 2.5rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.5rem
		}
	}
	img, svg {
		width: 100%;
		padding-top: $global-padding * 2;
		padding-bottom: $global-padding * 2;
	}
	p {
		margin-bottom: 0;
		font-size: 1.5rem;
		@include media-breakpoint-down(sm) {
			font-size: 1rem
		}
	}
}
