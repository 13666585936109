#languagePickerModal {
  text-align: center;
  padding: 0!important;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .modal-body {
    height: 25em;
    overflow-y: auto;
    label {
      margin-bottom: 0rem;
      padding-bottom: 0.5rem;
      cursor: pointer;
      width: 100%;
    }
    @include media-breakpoint-up(sm) {
      height: 40em;
    }
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

.language-picker {
  cursor: pointer;
  user-select: none;
  .scrollable-menu {
    height: auto;
    max-height: 40rem;
    overflow-x: hidden;
  }
}
