.pagination {
  list-style: none;
  display: block;
  text-align: center;
  .page-item {
    display: inline-block;
    font-size: $small-font-size;
    .page-link {
      color: $body-color;
      padding: $global-padding / 2 $global-padding;
      border: none;
      &:hover {
        text-decoration: none;
        background-color: $light-gray;
      }
    }
    &.active {
      a {
        background-color: $gray;
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
