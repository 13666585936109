.nav-tabs {
  display: block;
  text-align: center;
  border-bottom: solid 1px $gray;
  @include media-breakpoint-down(xs) {
    text-align: center;
    padding-right: 0;
    padding-bottom: $global-padding / 2;
  }
  .nav-item {
    display: inline;
    @include media-breakpoint-down(xs) {
      display: inline-block;
      float: none;
    }
    & + .nav-item {
      margin-left: 0;
    }
    .nav-link {
      padding: $global-padding / 2 $global-padding * 2;
      margin-top: $global-margin / 2;
      display: inline-block;
      transition: 0.3s;
      border: 0;
      text-transform: uppercase;
      @include media-breakpoint-down(sm) {
        padding: $global-padding / 2 $global-padding;
      }
      h3 {
        color: $skull-gray;
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
      &:hover, &.active {
        h3 {
          border: 0;
          color: $body-color;
        }
      }
    }
  }
}
.tab-pane {
  margin-top: $global-margin;
}

