.product {
  a {
    color: $darken-gray;
    text-decoration: underline;
  }
  &__info {
    @include media-breakpoint-down(sm) {
      h1 {
        font-size: 2rem;
      }
    }
    h3 {
      text-transform: uppercase;
    }
    &__name {
      font-weight: 300;
    }
    &__price {
      color: $skull-gray;
      &__undiscounted {
        text-decoration: line-through;
      }
      margin-bottom: $global-margin;
    }
    label {
      margin-bottom: $global-margin;
    }
    &__quantity {
      width: 17%;
      float: left;
      @include media-breakpoint-down(sm) {
        width: 47%;
      }
      input {
        height: 54px;
      }
    }
    &__button {
      width: 80%;
      float: right;
      margin-top: $global-margin * 2.5;
      button {
        min-width: 100%;
      }
      @include media-breakpoint-down(sm) {
        width: 50%;
        .btn.primary {
          padding: $global-padding;
        }
      }
    }
    td {
      padding-right: $global-padding;
    }
    &__form-error {
      margin-top: -$global-padding / 2;
      margin-bottom: $global-margin;
    }
    &__description {
      blockquote {
        margin: 1rem 0;
        padding: 0 2rem;
        font-style: italic;
      }
      img {
        max-width: 100%;
      }
    }
  }
  &__gallery {
    .carousel-control {
      &-prev {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .carousel-item {
      flex-flow: column;
      img {
        width: 100%;
      }
    }
    .carousel-indicators {
      position: relative;
      top: 100%;
      width: auto;
      left: 0;
      display: block;
      text-align: center;
      z-index: 3;
      li {
        display: inline;
        text-indent: 0;
        margin: 0;
        img {
          border: 1px solid $light-gray;
          padding: 10px;
          width: 80px;
        }
        &.active {
          img {
            box-shadow: 0 2px 0 0 $light-turquoise;
          }
        }
      }
    }
  }
  &__collections {
    padding-top: $global-padding * 4;
    h2 {
      text-align: center;
      text-transform: uppercase;
    }
  }
}
