@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
    max-width: 5rem;
    padding-top: $global-padding;
	  margin: auto;

    svg {
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
}
