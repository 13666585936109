.card-deck {
  .card {
    width: 100%;
    border-radius: 3px;
    margin-bottom: $global-margin * 2;
    padding: $global-padding;
    @include transition(background, 0.3s);
    @include media-breakpoint-down(sm) {
      width: 100%;
      display: inline-block;
      &:first-of-type {
        margin-bottom: $global-margin;
      }
    }
    p {
      margin-bottom: 0;
    }
    ul.icons {
      list-style: none;
      margin-bottom: 0;
      padding: 0;
      li {
        width: 49%;
        display: inline-block;
        a {
          line-height: 1.15rem;
          svg {
            float: right;
            cursor: pointer;
          }
          svg:hover path {
            fill: $darken-blue;
          }
        }
      }
    }
    .none {
      display: none;
    }
    .address-delete {
      margin-top: $global-margin / 4;
      .cancel {
        line-height: 2rem;
        padding-right: $global-padding;
        cursor: pointer;
        color: $blue;
        &:hover {
          color: $blue;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      form {
        text-align: center;
      }
    }
  }
}

.card {
  border-radius: 12px;
  &-default {
    background-color: $light-gray;
  }
}
