.navigation {
  ul {
    border-bottom: 1px solid $gray;
    margin-bottom: $global-margin;
    display: block;
    text-align: center;
    &.no-border {
      border-bottom: 0;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
      border-bottom: 0;
    }
    .nav-item {
      display: inline;
      + .nav-item {
        margin-left: 0;
      }
      a {
        color: $body-color;
        transition: 0.3s;
        text-transform: uppercase;
        display: inline-block;
        padding: $global-padding * 1.5;
        font-size: 1.5rem;
        &:hover {
          color: rgba($body-color, 0.6);
        }
      }

      @include media-breakpoint-up(md) {
        &__dropdown {
          display: inline-block;
          position: relative;

          &:hover > a {
            border-bottom: 3px solid black;
          }

          & > a {
            padding: 0.5rem;
            margin: 1rem;
            box-sizing: border-box;
            border-bottom: 3px solid $white;
          }
        }

        &__dropdown-content {
          display: none;
          position: absolute;
          z-index: 3;
          border: solid 1px $gray;
          background-color: $white;
          text-align: left;

          a {
            padding: 0;
            font-size: initial;
            text-transform: none;
            text-decoration: none;
          }

          .container {
            padding: $global-padding;
            max-height: 28rem;

            > ul {
              min-width: 120px;
              width: 360px;
              display: flex;
              flex-wrap: wrap;

              li {
                display: inline-block;
                max-width: 110px;

                &:not(:last-child) {
                  margin-right: $global-padding * 2;
                }
              }
            }
          }
        }

        &:hover .nav-item__dropdown-content {
          display: block;
        }
      }
    }
  }
}

.menu {
  .menu__item {
    ul {
      border-bottom: none;
      list-style-type: none;
      padding-left: 0;
      text-align: left;
      margin-bottom: 0;

      ul {
        padding-left: $global-padding;
      }

      li {
        text-align: left;
      }
    }

    a {
      text-decoration: none;
    }

    @include media-breakpoint-down(sm) {
      .nav-item__submenu ul {
        text-align: center;
        font-size: 0.8rem;

        ul {
          padding-left: 0;
          font-size: 0.7rem;
        }

        li {
          text-align: center;
        }
      }
    }
  }
}

.navbar {
  padding: 0;
  @include media-breakpoint-down(sm) {
    > .container-fluid {
      padding: 0;
    }
    svg {
      max-height: 30px;
      width: auto;
    }
  }
  @media (max-width: 370px) {
    svg {
      max-height: 23px;
      width: auto;
    }
  }
  &__login {
    p {
      font-size: 0.7rem;
      margin-bottom: 0;
      color: $skull-gray;
      line-height: 1.9rem;
      text-transform: uppercase;
      width: 100%;
    }
    ul {
      list-style: none;
      margin-bottom: 0;
      li {
        display: inline-block;
        padding: 0 $global-padding / 2;
        line-height: 1.9rem;
        a {
          color: $body-color;
          font-size: 0.7rem;
          text-transform: uppercase;
        }
      }
    }
  }
  &__logo {
    padding: $global-padding * 2 0;
    display: flex;
    align-items: center;
    a svg {
      height: 38px;
      width: auto;
      max-width: 113px;
    }
    .menu-icon-mobile {
      position: relative;
      display: inline-block;
      span {
        position: absolute;
        top: 1.3rem;
        left: 1.1rem;
        text-transform: uppercase;
        font-size: 0.45rem;
      }
      svg {
        height: 30px;
        vertical-align: top;
      }
      @media (max-width: 370px) {
        top: 0;
      }
    }
  }
  &__search {
    padding: $global-padding * 2 0;
    position: relative;
    form {
      svg {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        vertical-align: middle;
      }
      .btn {
        padding: $global-padding;
      }
    }
    .mobile-search-icon {
      float: right;
      padding-right: $global-padding;
    }
  }
  &__brand {
    background-color: $light-gray;
    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: $white;
      z-index: 5;
      border-bottom: 1px solid $gray;
    }
    .static {
      position: static;
    }
    &__checkout {
      z-index: 4;
      @include media-breakpoint-up(md) {
        line-height: 2.4rem;
      }
      .checkout__icon {
        color: $body-color;
        display: block;
        border: solid 1px transparent;
        border-radius: 4px 4px 0 0;
        padding: $global-padding * 2.5 $global-padding * 1.5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 4;
        @include media-breakpoint-down(sm) {
          position: relative;
          padding: 2rem 1rem 2rem 0;
        }
        &.hover {
          text-decoration: none;
          background: $white;
          border-top: solid 1px $gray;
          border-left: solid 1px $gray;
          border-right: solid 1px $gray;
          border-bottom: solid 1px $white;
          @include media-breakpoint-down(sm) {
            border: solid 1px transparent;
            background: none;
            padding: 2rem 1rem 2rem 0;
          }
        }
        &:hover {
          text-decoration: none;
        }
        .checkout-label {
          padding-right: $global-padding / 2;
          display: inline-block;
        }
        .navbar__brand__checkout__icon {
          display: inline-block;
          svg {
            vertical-align: middle;
          }
        }
        .badge {
          position: absolute;
          right: 10px;
          top: 30px;
          background-color: $light-turquoise;
          border-radius: 100px;
          color: $white;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 2;
          &.empty {
            background-color: $darken-gray;
          }
          @include media-breakpoint-down(sm) {
            right: 0;
            top: 20px;
          }
        }
      }
    }
    &__menu-toggle {
      padding-right: $global-padding;
      padding-left: $global-padding;
      vertical-align: super;
    }
  }
  &__menu {
    @include media-breakpoint-down(sm) {
      .navigation {
        position: fixed;
        left: -180px;
        top: 75px;
        height: 100vh;
        width: 180px;
        z-index: 4;
        background-color: $white;
        padding-left: 0;
        padding-right: $global-padding;
        padding-top: $global-padding * 2;
        transition: 0.3s ease-in-out;
        ul {
          text-align: left;
          .nav-item {
            width: 100%;
            text-align: left;
            a {
              padding: $global-padding / 2 $global-padding;
              font-size: 1rem;
            }
          }
        }
        &.open {
          left: 0;
          box-shadow: 1px 1px 10px 1px $gray;
        }
      }
    }
  }
}
