.product-filters {
  padding-bottom: $global-padding * 2;
  .btn {
    padding: $global-padding;
  }
  .hide {
    display: none;
  }
  .filter-section {
    &--closed {
      .filter-section {
        &__icon {
          img {
            transform: rotate(180deg);
          }
        }
        &__content {
          max-height: $global-padding * 0.5;
        }
      }
    }
    &__header {
      border-bottom: 1px solid $gray;
      cursor: pointer;
      display: grid;
      grid-template-columns: calc(100% - 20px) 20px;
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      h3 {
        font-weight: bold;
        padding-right: $global-padding * 0.5;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &__content {
      transition: $transition-base;
      overflow: hidden;
      max-height: 500px;
      .price-field {
        padding: $global-padding 0;
        input {
          width: 40%;
          display: inline-block;
        }
        span {
          width: 20%;
          text-align: center;
          display: inline-block;
        }
      }
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition-base;
      img {
        width: 8px;
        height: 8px;
      }
    }
  }
  ul {
    padding: $global-padding / 2 0;
    list-style: none;
    margin-bottom: 0;
    li {
      input {
        position: relative;
        top: -2px;
        margin-right: $global-margin;
      }
    }
  }
  button {
    width: 100%;
  }
  &__attributes {
    ul {
      li {
        label {
          cursor: pointer;
          outline: 0;
        }
      }
    }
  }
  &__categories {
    padding: $global-padding 0 0;
    @include media-breakpoint-down(sm) {
      padding: $global-padding * 3 0 $global-padding * 2;
    }
    @media (max-width: 370px) {
      padding-top: 5rem;
    }
    h2 {
      font-size: 1.8rem;
      color: $body-color;
      padding-bottom: 0;
    }
    ul {
      list-style: none;
      padding: 0 $global-padding;
      margin-bottom: 0;
      li {
        padding-left: 0;
        a {
          font-size: 1.2rem;
          color: $body-color;
          padding-right: $global-padding/2;
        }
      }
    }
    &__parents {
      padding-left: $global-padding;
      a {
        color: $body-color;
      }
      svg {
        vertical-align: text-top;
        margin-right: $global-margin / 2;
      }
    }
    &__childs {
      padding-left: $global-padding * 2.5;
      a {
        font-size: 1rem;
      }
      &.no-parent {
        padding-left: $global-padding;
      }
    }
  }
}

.filters-menu {
  &__label {
    text-transform: uppercase;
    cursor: pointer;
    padding-left: $global-padding;
  }
  &__icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: $blue;
    margin-left: $global-margin/2;
    border-radius: 50%;
  }
  &__wrapper {
    @include media-breakpoint-down(sm) {
      padding-top: 8px;
    }
  }
}

.filters-toggle {
  margin: 1.1rem 0 1rem;
  cursor: pointer;
}
.sort-by {
  text-align: left;
  position: relative;
  margin-bottom: $global-margin;
  .btn-link {
    font-size: 14px;
    padding-right: $global-padding;
    color: $body-color;
    text-transform: uppercase;
    outline: 0;
    svg {
      vertical-align: baseline;
      margin-left: $global-padding/2;
      height: 10px;
      width: 15px;
    }
  }
  ul {
    width: 20rem;
    list-style: none;
    background: $light-gray;
    box-shadow: 3px 3px 3px 0px rgba($gray, 0.75);
    position: absolute;
    top: 2.5rem;
    z-index: 999;
    padding: $global-padding $global-padding * 2;
    li {
      text-align: left;
      padding-top: $global-padding/2;
      overflow: hidden;
      border-bottom: solid 1px $gray;
      padding-bottom: $global-padding;
      .col-6 {
        div {
          cursor: pointer;
        }
      }
      span {
        &:hover {
          text-decoration: underline;
        }
      }
      svg {
        margin-top: $global-padding/3;
      }
      a {
        color: inherit;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
      &:after {
        content: "";
      }
    }
    &:before {
      content: "";
      display: block;
      width: 15px;
      height: 10px;
      position: absolute;
      top: -10px;
      right: 15px;
      background-color: $light-gray;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }
  .click-area {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    &.hide {
      display: none;
    }
  }
  .sort-order-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    img {
      height: 10px;
    }
    svg {
      width: 8px;
      height: 8px;
    }
  }
}

.clear-filters {
  display: inline-block;
  margin: $global-margin * 0.5 0 $global-margin;
}

.load-more {
  clear: both;
  text-align: center;
}
