// Razorpay does not support custom button classes
// so we need to reimplement the bootstrap button.
.razorpay-payment-button {
  cursor: pointer;
  @include button-variant($primary, $primary);
  @include button-size(
      $btn-padding-y, $btn-padding-x,
      $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);
}
