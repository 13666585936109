.checkout {
  margin-bottom: $global-margin * 5;
  h2,
  h3 {
    text-transform: uppercase;
    margin: 0 0 $global-margin * 2 0;
  }
  hr:not(.margin-default) {
    margin: $global-margin * 3 0 $global-margin * 2;
  }
  .primary {
    margin-top: $global-margin;
  }
  &__edit-link {
    margin-top: $global-margin;
  }
  &__header {
    background-color: $light-gray;
    padding: $global-padding * 1.5 0 $global-padding;
    margin-bottom: $global-margin;
    @include media-breakpoint-down(sm) {
      padding: $global-padding 0;
      & ~ .maincontent {
        margin-top: 0;
      }
    }
    a:hover {
      text-decoration: none;
    }
    svg {
      vertical-align: baseline;
      margin-right: $global-margin;
      padding-bottom: 2px;
      width: 113px;
      @include media-breakpoint-down(sm) {
        max-height: 34px;
        vertical-align: text-bottom;
        margin-right: 0;
        padding-bottom: 5px;
      }
    }
    h1 {
      display: inline-block;
      font-weight: 300;
      text-transform: uppercase;
      @include media-breakpoint-down(sm) {
        font-size: 1.8rem;
        margin-bottom: 0;
      }
    }
  }
  &__login {
    margin: $global-margin * 5 0 $global-margin * 3;
    border: 1px solid $gray;
    border-radius: $button-border-radius;
    text-align: center;
    h3 {
      margin-bottom: $global-margin * 3;
    }
    .btn {
      margin: $global-margin * 2 auto 0;
    }
    &__continue {
      padding: $global-padding * 2 0 $global-padding;
    }
    &__form {
      background-color: $light-gray;
      padding: $global-padding * 2 $global-padding * 6 $global-padding * 2;
      @include media-breakpoint-down(md) {
        padding: $global-padding * 2;
      }
      .form-group {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &__review {
    background-color: $light-gray;
    padding: $global-padding $global-padding * 2;
    border-radius: 3px;
    h3 {
      margin: $global-margin 0;
    }
    &__section {
      border-top: 1px solid $gray;
      padding-top: $global-padding;
      .input-btn {
        input {
          border-radius: 12px;
        }
        .btn {
          height: calc(2.25rem + 2px);
          padding: $global-padding / 2;
        }
        .btn-voucher-remove {
          position: relative;
        }
      }
      input {
        padding: $global-padding;
      }
    }
    &__tittle {
      margin-bottom: $global-margin * 2;
    }
    &__variant {
      color: $skull-gray;
    }
    @include media-breakpoint-down(sm) {
      margin-top: $global-margin * 3;
    }
  }
  &__thankyoupage {
    text-align: center;
    margin-top: $global-margin * 6;
    @include media-breakpoint-down(sm) {
      margin-top: $global-margin * 3;
    }
    h3 {
      margin-bottom: $global-margin * 3;
    }
    .btn {
      margin-top: $global-margin;
    }
    span {
      color: $darken-gray;
    }
    h1 {
      font-weight: 300;
      margin-top: -$global-margin * 4;
    }
    &__form {
      margin-top: $global-margin * 6;
      margin-bottom: $global-margin * 3;
      border: 1px solid $light-gray;
      border-radius: $button-border-radius;
      @include media-breakpoint-down(sm) {
        margin-top: $global-margin * 3;
      }
      .col-md-6 {
        padding: $global-padding * 3 0 $global-padding * 2;
        &:first-of-type {
          background-color: $light-gray;
          padding: $global-padding * 3 $global-padding * 4 $global-padding * 2;
          @include media-breakpoint-down(md) {
            padding: $global-padding * 3 $global-padding * 2 $global-padding * 2;
          }
        }
      }
      img {
        margin-bottom: $global-margin * 1.2;
        @include media-breakpoint-only(md) {
          margin-bottom: $global-margin * 2.4;
        }
      }
    }
  }
  &__new-address {
    grid-column-end: span 2;
    @include media-breakpoint-down(md) {
      grid-column-end: span 1;
    }
    &-container {
      grid-column-end: span 2;
      @include media-breakpoint-down(md) {
        grid-column-end: span 1;
      }
    }
  }
  &__shipping {
    .btn {
      grid-column-start: 2;
      width: 100%;
      @include media-breakpoint-down(md) {
        grid-column-start: 1;
      }
    }
  }
  &__shipping-methods {
    p {
      margin: $global-margin * 2 0;
    }
    .address {
      width: calc(50% - #{$global-margin});
    }
    .btn {
      grid-column-start: 2;
      width: 100%;
      @include media-breakpoint-down(md) {
        grid-column-start: 1;
      }
    }
    &-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $global-margin * 2;
      grid-row-gap: $global-margin;
      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
      }
      label {
        cursor: pointer;
        display: block;
        margin: 0;
      }
      .btn {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }
  &__payment {
    h3 {
      margin: $global-margin * 2 0 $global-margin;
    }
    &-form {
      label {
        cursor: pointer;
        display: block;
        margin: 0;
      }
      .btn {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      &__choice {
        margin-bottom: $global-margin;
      }
    }
  }
  &__payments {
    &-card-expiry-slash {
      pointer-events: none;
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
  &__street_address_2 {
    padding-top: $global-padding * 2;
  }
  &__addresses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $global-margin * 2;
    &--full-width {
      grid-template-columns: 1fr;
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
    &-panel {
      label {
        cursor: pointer;
        display: block;
        margin-right: 0;
      }
    }
  }
  &__billing {
    &-form {
      p {
        margin: $global-margin * 2 0;
      }
    }
  }
}

.checkout-preview {
  h3 {
    color: $skull-gray;
  }
  &__line {
    &.last {
      border-bottom-color: $skull-gray;
    }
    .col-5 {
      margin-top: $global-margin / 2;
      @include media-breakpoint-down(sm) {
        text-align: right;
      }
    }
    &__product {
      a {
        transition: 0.3s;
        &:hover {
          color: $skull-gray;
        }
        p {
          display: inline-block;
          margin-top: $global-margin / 2;
          margin-bottom: 0;
          line-height: 1rem;
          small {
            color: $skull-gray;
          }
        }
        img {
          vertical-align: top;
          margin-right: $global-margin;
        }
      }
    }
    &__quantity {
      @include media-breakpoint-down(sm) {
        margin-bottom: $global-margin / 2;
      }
      form {
        display: inline-block;
        input {
          width: 62px;
          margin-right: 10px;
        }
      }
      &-error {
        display: block;
      }
      img {
        max-width: 18px;
      }
    }
  }
  &__total {
    padding-top: $global-padding;
    margin-bottom: $global-margin;
    p {
      margin-bottom: 0;
    }
    &__subtotal {
      color: $skull-gray;
      @include media-breakpoint-up(sm) {
        padding-left: calc(60px + #{$global-padding} * 2);
      }
      &--shipping {
        color: $body-color;
      }
    }
  }
  &__delivery-info {
    p {
      margin-bottom: 0;
      color: $body-color;
      display: inline-block;
    }
    &__price {
      text-transform: uppercase;
    }
  }
  &__empty {
    text-align: center;
    img {
      margin: $global-margin * 2 0;
    }
    .btn {
      margin: $global-margin * 2 0 $global-margin * 5;
    }
  }
  &__submit {
    @media (max-width: 370px) {
      width: 100%;
    }
  }
  &__clear {
    @media (max-width: 400px) {
      margin-top: $global-margin;
    }
    @media (min-width: 470px) {
      margin-right: $global-margin;
    }
    @media (max-width: 370px) {
      width: 100%;
    }
    margin-right: $global-margin;
  }
  &-item-delete {
    cursor: pointer;
    display: inline-block;
    margin-left: $global-margin / 2;
    svg {
      vertical-align: text-top;
    }
  }
}
