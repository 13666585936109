.breadcrumbs {
  margin: $global-margin 0 $global-margin * 1.5;
  text-transform: uppercase;
  li {
    display: inline;
    &:last-of-type {
      a {
        color: $skull-gray;
        font-weight: 600;
      }
    }
    &:not(:first-of-type) {
      margin-left: $global-margin / 3;
    }
    &:not(:last-of-type):after {
      content: "\\";
      display: inline-block;
      margin-left: $global-margin / 3;
    }
    a {
      color: $gray;
    }
  }
}
